* {
  font-family: 'Futura'!important; 
}

.golden {
color: #e5bd6c;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.typist-text {
  font-size: 18px;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
  margin-bottom: 2vh;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typewriter h5 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typewriter 4s steps(44, end),
    blink-caret .5s step-end infinite;
}

.shadowedPNG {
  filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.7));
}

.bitcoinSymbol {
  transform: rotate(-13deg);

}
