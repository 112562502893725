body {
  margin: 0;
  font-family: 'Futura';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
@font-face {
  font-family: 'Futura';
  src: url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Light.woff2') format('woff2'),
       url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Medium.woff2') format('woff2'),
       url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/src/assets/transfonter.org-20240617-115006/FuturaBT-BoldCondensed.woff2') format('woff2'),
       url('/src/assets/transfonter.org-20240617-115006/FuturaBT-BoldCondensed.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Book.woff2') format('woff2'),
       url('/src/assets/transfonter.org-20240617-115006/FuturaBT-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Futura', Arial, Helvetica, sans-serif;
}

.bold {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.fatFutura { 
  font-weight: 500;
  font-family: 'Futura', Arial, Helvetica, sans-serif;
}